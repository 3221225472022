/********************************************
* LISTS PAGES UNCOMPRESSED STYLEsss
********************************************/
@import "../base/variables";
@import "../base/mixins";

.n49filter{
  ::-webkit-scrollbar {
    background-color: $primary-color;
    width: 8px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;    
  }
  ::-webkit-scrollbar-thumb {
    background: $second-color;
    border-radius: 10px;
    width: 5px;
  }
}

.lists .n49filter .box.box-current .content-box .content-list li,
.n49filter .box.box-current .content-box .content-list li .rm-item{
  color: $second-color !important;
}

.n49filter .box .block.prices-block .content-block .price-label input {
  border: 1px solid $quart-color;
  padding: 0 9px !important;
  height: 30px;
}
.n49filter .box .block .content-list li label input[type="checkbox"]{
  border-radius: unset !important;
  border: 1px solid $second-color !important;
}

.img-category{
  img{
    width: 100%;
    height: 100%;
  }
}

.lists{
  .banner-principal{
    position: relative;
    margin-top: -5px;

    ul{
      list-style: none;
    }
    .owl-dots{
      position: absolute;
      right: 50%;
      top: 90%;
      @include transform(translateY(-50%));
      display: flex;

      .owl-dot{
        &:not(:last-child){
          margin-right: 4px;
        }
        &.active{
          span{
            background: $second-color;
          }
        }
        span{
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid $second-color;
        }
      }
    }
    .control{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      padding: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $second-color;
      text-align: center;
      z-index: 2;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

      &:hover{
        background: $second-color;
      }
      &.prev{
        left: 0;
        border-radius: 0 5px 5px 0;

        .fa{
          right: 2px;
        }
      }
      &.next{
        right: 0;
        border-radius: 5px 0 0 5px;

        .fa{
          left: 2px;
        }
      }
      .fa{
        color: $primary-color;
        position: relative;
      }
    }
  }
  .dicas{
    position: relative;
    padding-bottom: 30px;
    margin-right: 1%;
    text-align: center;

    .box{
      border-top: 1px solid $quart-color;
      border-bottom: 1px solid $quart-color;
      padding-top: 30px;
      padding-bottom: 30px;
      @include media('custom-font'){
        font-size: 24px;
        p{
          font-size: 14px;
        }
      }        

      h2{
        padding-bottom: 12px;
        font-size: 22px;
      }        
    }
    @include media('small'){
      margin-left: 0;
    }
  }
  .manufacturer-title{
    background-color: $quart-color;
    padding: 20px 19px 20px 22px;
  }
  .manufacturer-list{
    ul{
      list-style: none;
    }
    .manufacturer-heading{
      font-size: 17px;
      font-weight: bold;
      background: $tertiary-color;
      color: $primary-color;
      padding: 5px 10px;
      margin-bottom: 10px;
    }
    .manufacturer-content{
      font-size: 15px;
      li{
        padding: 2px;

        a{
          color: $font-color;
          font-weight: 500;
        }
      }
    }
  }
  .top-description{
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border: solid 1px $quart-color;
    padding: 20px;
    margin-bottom: 20px;

    .col-image{
      padding: 0;
      img{
        width: auto;
        @include media('small'){
          margin-bottom: 15px;
        }
      }
    }
    .col-text{
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #666666;
      h3{
        font-size: 13px;
        font-weight: bold;
        color: $tertiary-color;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
    }
  }
  .total-itens{
    @include media("small"){
      text-align: center;
    }
    @include media('custom-font'){
      font-size: 14px !important;
    }
  }
  .top-page{
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h2{
      font-size: 20px;
      font-weight: 500;
      color: $second-color;
      text-transform: uppercase;
      text-align: center;
      @include media("mf-large"){
        display: flex;
        align-items: center;
        padding-right: 15px;
      }
      @include media('custom-font'){
        font-size: 22px;
      }
      .img-cat{
        width: 40px;
        height: 40px;
        background-color: $second-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
        .title-icon{
          max-width: 20px;
        }
      }
    }
    .select-ordenar{
      display: flex;
      align-items: center;
      label{
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 0;
        margin-right: 15px;
        color: $second-color;
        white-space: nowrap;
        @include media('custom-font'){
          font-size: 15px;
        }        
      }
      .select-box{
        position: relative;
        &:after{
          content: "\f107";
          font-family: "FontAwesome";
          font-size: 15px;
          color: $second-color;
          position: absolute;
          right: 12px;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
          pointer-events: none;
        }
        select{
          width: 200px;
          height: 40px;
          border: 1px solid #e9e9e9;
          background: white;
          -webkit-appearance: none;
          font-size: 14px;
          font-weight: 300;
          color: $font-color;
          padding: 0 12px;
          outline: none;
          box-shadow: none;
          border-radius: unset;
        }
      }
    }
  }
  .notfound-top{
    margin-top: 50px;
  }
  .prods-not-found{
    margin-bottom: 50px;

    p{
      font-size: 17px;
      margin-bottom: 15px;
      font-weight: 300;
    }
  }
  .produtos{
    .product-item-block{
      margin-bottom: 18px;
      min-height: 429px;
    }
    #click-pagination-less, 
    #click-pagination-more{
      border: 0;
      background: $tertiary-color;
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.36;
      text-transform: uppercase;
      width: 100%;
      height: 53px;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      box-shadow: 11px 13px 35px 0 rgba(0,0,0,0.2);
      line-height: 2.36;
      @include media("mf-small"){
        width: 245px;
      }
      @include media('custom-font'){
        font-size: 15px;
      }      
      .fa-plus-circle{
        display: none;
      }
    }
  }
  .n49filter{
    .box{
      margin-bottom: 0;
      border: solid 1px $quart-color;
      &.box-current{
        margin-bottom: 20px;
        .top-box .title:before{
          display: none;
        }
        .content-box .content-list li{
          background: white;
          border-bottom: 0;
          border-top: 1px solid $quart-color;
          font-size: 13px;
          color: #9b9b9b;
          font-weight: 600;
        }
        .top-box{
          font-weight: 600;
        }
      }
      .top-box{
        background: transparent;
        height: 47px;
        .title{
          color: $second-color;
          font-size: 15px;
          font-weight: 300;
          display: flex;
          align-items: center;
          text-transform: unset;
          &:before{
            content: "\f0b0";
            font-family: "FontAwesome";
            margin-right: 7px;
          }
        }
        .clean-filters{
          height: auto;
          margin-right: 15px;
          .right-icon,
          .overlay{
            display: none
          }
          &:before{
            content: "Limpar filtros";
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 300;
            color: $primary-color;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .block{
        padding: 0;
        margin-bottom: 0;
        &.closed{
          .top-block .title{
            border-bottom: 0;
            &:after{
              content: "\f106";
              padding-top: 0;
            }
          }
        }
        &.sizes-block{
          .content-list li label .sizes-item-block span{
            font-weight: normal;
          }
        }
        .top-block{
          padding: 0 12px;
          border-top: 2px solid $quart-color;
          .title{
            height: 45px;
            display: flex;
            align-items: center;
            padding: 0;
            border-bottom: 2px solid $quart-color;
            font-size: 14px;
            font-weight: normal;
            text-transform: none;
            color: $second-color;
            position: relative;
            &:after{
              content: "\f107";
              display: block;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: $second-color;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              font-size: 10px;
              padding-top: 1px;
              padding-left: 1px;
            }
          }
        }
        .content-block{
          padding: 17px 12px;
        }
        .content-list{
          li{
            .badge{
              background: transparent;
              font-size: 13px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: $second-color;
            }
          }
        }
        .content-list li label{
          font-weight: normal;
          color: $second-color;
          input[type="radio"],
          input[type="checkbox"]{
            // border: 1px solid $second-color;
            width: 20px;
            height: 20px;

            &:hover{
              border: 1px solid $quart-color;
            }
            &:checked{
              &:after{
                background: $second-color;
              }
            }
          }
          p{
            font-size: 13px;
            font-weight: 300;
            color: $font-color;
          }
        }
      }
      .block.prices-block {
        .slider{
          .slider-selection{
            background: $second-color;
          }
          .slider-track-high,
          .slider-track-low{
            background: $quart-color;
          }
          .slider-handle{
            background: $second-color;
            background-image: url('../../images/btprice-copy.svg');
          }
        }
      } 
    }
  }
  .product-carousel {
    width: 100vw;
    margin-left: calc(-50vw + 50% - 8px);
    position: relative;

    .title-icon{
      background-image: url(../../images/cart.svg);
    }
  }
  .filtros-button{
    display: none;
  }

  @include media('small'){
    .top-page{
      .select-ordenar{
        margin-top: 15px;
        flex-wrap: wrap;

        label{
          margin-bottom: 5px;
        }
        .select-box select,
        .select-box{
          width: 100%;
        }
      }
    }
    .n49filter{
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      transition: 200ms ease;
      background: white;
      padding-bottom: 20px;
    }
    .filtros-button{
      position: fixed;
      bottom: 50px;
      z-index: 15;
      background: $tertiary-color;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: $primary-color;
      border: 2px solid $primary-color;
      display: flex;
      box-shadow: 11px 13px 35px 0 rgba(0,0,0,0.2);
      justify-content: center;
      cursor: pointer;
      right: 14px;
      .fa{
        font-size: 24px;
      }
    }
  }
  .manufacturers{
    padding-top: 30px;
    .index{
      padding-bottom: 20px;
      .index-item{
        color: $second-color;
      }
    }
    .manufacturer-content{
      ul{
        margin-bottom: 20px;
        a{
          color: $tertiary-color;

          span{
            display: block;
            text-align: center;
          }
          img{
            max-width: 100px;
          }
        }
      }
    }
  }
}
@include media('small'){
  .filtros-on{
    .header-mob{
      z-index: 0;
    }
    .n49filter{
      visibility: visible;
      opacity: 1;
      z-index: 100;
      overflow: auto;
      .top-box{
        i{
          display: block !important;
          position: relative;
          left: 20px;
          width: 30px;
          height: 30px;
          text-align: center;
          font-size: 18px;
          border-radius: 30px;
          margin-right: -10px;
          padding-top: 5px;
        }
      }
    }
  }
}
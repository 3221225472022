/**************************************
* DEFAULT VARIABLES
**************************************/
$primary-color: #ffffff;
$second-color:  #000000; //cor da loja
$tertiary-color:#ff5b00; //laranja principal loja
$quart-color:   #e9e9e9; //linhas divisórias
$contrast-color:#ef643a; //laranja destaque
$font-color:    #000000; //cor dos textos

$color-1: #fbb03f; /// amarelo
$color-2: #383838; /// cinza escuro
$color-3: #f2efea; /// cinza claro
$color-4: #f4f4f4; /// cinza submenu
$contrast-1: #fff;
$contrast-2: #fbb03f;
$contrast-3: #4a4a4a;

/**************************************
* DEFAULT MIXINS AND FUNCTIONS
**************************************/

/**************************************
* Limite de linhas
  Exemplo de uso:
  p{
    font-size: 13px;
    color: black;
    @include line-limit(3, 21px);
  }
**************************************/
@mixin line-limit($lines, $height){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-height: $height;
  max-height: $height*$lines;
}

@mixin placeholder(){
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

@mixin clearfix {
  &:before,
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}

@mixin no-select{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
        user-select: none;
}

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin column($num, $gap){
  -moz-column-count: $num;
  -moz-column-gap: $gap;
  -webkit-column-count: $num;
  -webkit-column-gap: $gap;
  column-count: $num;
  column-gap: $gap;
}

@mixin media($size){
  @if $size == xxs{
    @media(max-width: 450px){ @content; }
  }
  @if $size == lxs{
    @media(min-width: 451px){ @content; }
  }
  @if $size == custom-xs{
    @media(min-width: 550px){ @content; }
  }    
  @if $size == xs{
    @media(max-width: 767px){ @content; }
  }
  @if $size == xsm{
    @media(min-width: 449px) and (max-width: 768px){ @content; }
  }  
  @if $size == sm{
    @media(min-width: 768px) and (max-width: 991px){ @content; }
  }
  @if $size == xmd{
    @media(min-width: 451px) and (max-width: 991px){ @content; }
  }  
  @if $size == md{
    @media(min-width: 992px) and (max-width: 1199px){ @content; }
  }
  @if $size == lg{
    @media(min-width: 1200px){ @content; }
  }
  @if $size == xlg{
    @media(min-width: 1350px){ @content; }
  }
  @if $size == xxlg{
    @media(min-width: 1600px){ @content; }
  }
  @if $size == small{
    @media(max-width: 991px){ @content; }
  }
  @if $size == large{
    @media(min-width: 992px){ @content; }
  }
  @if $size == custom-02{
    @media(min-width: 360px) and (max-width: 768px){ @content; }
  }

  @if $size == custom-font{
    @media(min-width: 360px) and (max-width: 500px){ @content; }
  }  

  /* Breakpoints mobile first */
  @if $size == mf-small{   /* tablet */
    @media(min-width: 450px){ @content; }
  }
  @if $size == custom-small{   /* tablet */
    @media(min-width: 500px){ @content; }
  }  
  @if $size == mf-x-small{ /* tablet-full */
    @media(min-width: 768px){ @content; }
  }  
  @if $size == mf-large{   /* desktop */
    @media(min-width: 992px){ @content; }
  }
  @if $size == mf-x-large{ /* desktop full */
    @media(min-width: 1200px){ @content; }
  }  
}